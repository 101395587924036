<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="包材型号">
          <a-input
            v-decorator="[
              'packing_code',
              {
                rules: [
                  { required: true, message: '请输入包材型号' },
                  { max: 256, message: '超出最大长度(256)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="Country">
          <a-input
            v-decorator="[
              'country',
              {
                rules: [
                  { required: true, message: '请输入 Country' },
                  { max: 256, message: '超出最大长度(256)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="最大申报价值">
          <a-input
            v-decorator="[
              'max_declaration_value',
              {
                rules: [
                  { required: true, message: '请输入最大申报价值' },
                  { max: 256, message: '超出最大长度(256)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="分拣道口 A">
          <CrossingSelect
            v-decorator="['target_crossing_a', { rules: [{ required: true, message: '请选择分拣道口 A' }] }]"
            productionLine="a"
          />
        </a-form-item>
        <a-form-item label="分拣道口 B">
          <CrossingSelect
            v-decorator="['target_crossing_b', { rules: [{ required: true, message: '请选择分拣道口 B' }] }]"
            productionLine="b"
          />
        </a-form-item>
        <a-form-item label="最大重量(kg)">
          <a-input-number
            v-decorator="[
              'max_weight',
              {
                rules: [{ required: true, message: '请输入最大重量' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="最大长度(mm)">
          <a-input-number v-decorator="['max_length']" style="width: 100%" />
        </a-form-item>
        <a-form-item label="最大宽度(mm)">
          <a-input-number v-decorator="['max_width']" style="width: 100%" />
        </a-form-item>
        <a-form-item label="最大高度(mm)">
          <a-input-number v-decorator="['max_height']" style="width: 100%" />
        </a-form-item>
        <a-form-item label="非该包装方式">
          <a-input
            v-decorator="[
              'not_packing_method',
              {
                rules: [{ message: '请输入非该包装方式' }, { max: 256, message: '超出最大长度(256)' }],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="启用状态">
          <a-switch v-decorator="['is_enable', { initialValue: true, valuePropName: 'checked' }]" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { overlimitInterceptionCreate } from "@/apis/sorting";

export default {
  components: {
    CrossingSelect: () => import("@/components/CrossingSelect"),
  },
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
      productionLine: undefined,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          overlimitInterceptionCreate(values)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("新增成功");
              this.handleCancel();
            })
            .catch((error) => {
              if (error.non_field_errors && error.non_field_errors.length > 0) {
                this.$message.error(error.non_field_errors[0]);
              } else {
                const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                  acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                  return acc;
                }, {});
                this.dataForm.setFields(errorFields);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
    onChangeProductionLine(value) {
      this.productionLine = value;
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
